<template>
    <MainPaperFormView :Type="'Template'" :Status="0" :canWatch="editableForm" >
    </MainPaperFormView>
    <!-- <Splitter layout="vertical" style="height: 100%;"> -->
    <!--     <SplitterPanel :size="30" :minSize="10" style="overflow: hidden;position: relative;" > -->
    <!--         <FormDataTable ref="dataTable" :Type="'draft'" :Status="0" :Loading="Loading" /> -->
    <!--     </SplitterPanel> -->
    <!--     <SplitterPanel :size="70" :minSize="10" :maxSize="90" ref="mainPanelRef" v&#38;#45;on:scroll="handleScroll"> -->
    <!--         <template v&#38;#45;if="editableForm"> -->
    <!--             <div style="position: relative; height: 100%;"> -->
    <!--                 <operationButtonBar Type="Template" /> -->
    <!--                 <div class="customScrollPanel" style="position: absolute; top: 40px; left: 0px; right: 0px; bottom: 0px; overflow: hidden auto"> -->
    <!--                     <editTemplateForm   @setScrollAmount="setScrollAmount($event)" :isPreviewPanel="isPreview" /> -->
    <!--                     <template v&#38;#45;if="showSpinner"> -->
    <!--                         <div class="progressSpinnerContainer"> -->
    <!--                             <ProgressSpinner /> -->
    <!--                         </div> -->
    <!--                     </template> -->
    <!--                 </div> -->
    <!--                 <!&#38;#45;&#38;#45; <ScrollPanel class="customScrollPanel" style="position: absolute; top: 50px; left: 0px; right: 0px; bottom: 0px;"> &#38;#45;&#38;#45;> -->
    <!--                 <!&#38;#45;&#38;#45;     <editTemplateForm   @setScrollAmount="setScrollAmount($event)" :isPreviewPanel="isPreview" /> &#38;#45;&#38;#45;> -->
    <!--                 <!&#38;#45;&#38;#45;     <template v&#38;#45;if="showSpinner"> &#38;#45;&#38;#45;> -->
    <!--                 <!&#38;#45;&#38;#45;         <div class="progressSpinnerContainer"> &#38;#45;&#38;#45;> -->
    <!--                 <!&#38;#45;&#38;#45;             <ProgressSpinner /> &#38;#45;&#38;#45;> -->
    <!--                 <!&#38;#45;&#38;#45;         </div> &#38;#45;&#38;#45;> -->
    <!--                 <!&#38;#45;&#38;#45;     </template> &#38;#45;&#38;#45;> -->
    <!--                 <!&#38;#45;&#38;#45; </ScrollPanel> &#38;#45;&#38;#45;> -->
    <!--             </div> -->
    <!--         </template> -->
    <!--         <div v&#38;#45;else class="p&#38;#45;p&#38;#45;4"> -->
    <!--             <Nico /> -->
    <!--         </div> -->
    <!--     </SplitterPanel> -->
    <!-- </Splitter> -->
</template>

<script>
// import http from '../mixins/api'
// import Splitter from 'primevue/splitter';
// import SplitterPanel from 'primevue/splitterpanel';
//
// import editTemplateForm from '@/components/editTemplateForm'
// import Nico from '@/components/Nico';
// import ScrollPanel from 'primevue/scrollpanel';
// import ProgressSpinner from 'primevue/progressspinner';
// import operationButtonBar from '@/components/operationButtonBar'
// // @ is an alias to /src
// import store from '@/store'
// import FormDataTable from '@/components/FormDataTable'
import MainPaperFormView from '@/components/MainPaperFormView'

export default {
    name: 'Draft',
    components: {
        MainPaperFormView,
    },
    created() {
    },
    beforeRouteLeave(to, from, next) {
        const edited = this.$store.getters["editTemplate/getEdited"]
        if(edited) {
            const ok = confirm(this.$i18n.tc("formDataTable.isNotSave"))
            if(ok) {
                this.$store.commit("editTemplate/setEdited", false)
            } else {
                return
            }
        }
        next()
    },
    data: function() {
        return {
            scrollAmount: 0,
            Loading: false,
            scrollPreviewAmount: 0,
            isPreview: false,
        }
    },
    computed: {
        showSpinner: {
            get: function() {
                return this.$store.getters["gridDataStore/getShowSpinner"]
            }
        },
        editableForm: {
            get: function() {
                return this.$store.getters["gridDataStore/isEditableForm"]
            },
        },
    },
    watch: {
    },
    methods: {
    }
}
</script>


